<script>
let icon1 = require("@/assets/industryMap/monitor-icon-warning.png");
let icon2 = require("@/assets/industryMap/monitor-icon-error.png");
let icon3 = require("@/assets/industryMap/monitor-icon.png");
// 0 没有预警
// 1有预警 没处理
// 2有预警 处理中
// 3没预警 已处理
// 三种图标展示情况
let ICON = {
  0: icon3,
  1: icon1,
  2: icon2,
  3: icon3,
};
import request from "@/utils/request";
import {
  Icon,
  Style,
  Circle as CircleStyle,
  Fill,
  Text,
  Stroke,
} from "ol/style";
import Overlay from "ol/Overlay";
import Map from "ol/Map.js";
import View from "ol/View.js";
import * as olControl from "ol/control";
import { getWidth, getTopLeft } from "ol/extent.js";
import TileLayer from "ol/layer/Tile.js";
import { get as getProjection } from "ol/proj.js";
import WMTS from "ol/source/WMTS.js";
import WMTSTileGrid from "ol/tilegrid/WMTS.js";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as SourceLayer, Cluster } from "ol/source";
import Feature from "ol/Feature";
import { Point, LineString, Polygon } from "ol/geom";
// import {addWindow, removeWindow } from '@/utils/addWindow'
export default {
  data() {
    return {
      map: null,
      type: true,
      currentId: null,
      currentDate: new Date().getTime()
    };
  },
  // mounted () {
  //   this.initMap()
  // },
  methods: {
    addAreaLayer(data, config) {
      console.log(data, "asdasd");
      if (this.areaLayer) {
        this.areaLayer.getSource().removeFeature(this.areaPoly);
        this.map.removeLayer(this.areaLayer);
        this.areaLayer = null;
        this.areaPoly = [];
      }
      var areaFeature = new Feature({
        name: "area",
        geometry: new Polygon(data),
      });
      const areaVectorSource = new SourceLayer({
        features: [areaFeature],
      });

      const areaVectorLayer = new VectorLayer({
        source: areaVectorSource,
        style: new Style({
          fill: new Fill({
            color: "rgba(51,105,255,0.6)",
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 2,
          }),
        }),
      });

      this.map.addLayer(areaVectorLayer);
      this.areaLayer = areaVectorLayer;
      this.areaPoly = areaVectorSource;
      let view = this.map.getView();
      view.setCenter(config.center || [119.68643, 29.79388]);
      view.setZoom(config.zoom || 16);
      this.map.render();
    },
    setLabelMarkers(facilityCoordinatesVOS, icon) {
      const that = this;
      // console.log(
      //   facilityCoordinatesVOS,
      //   "facilityCoordinatesVOSfacilityCoordinatesVOS"
      // );
      const posta = facilityCoordinatesVOS[0].coordinates.split(",");
      let view = this.map.getView();
      view.setCenter([Number(posta[0]), Number(posta[1])]);
      const poly = [];
      const Layer = new VectorLayer({
        // 源于"ol/layer"
        // softMove()内的时间容器
        source: new SourceLayer(), // 源于"ol/source"
      });
      facilityCoordinatesVOS.map((item) => {
        const post = item.coordinates.split(",");
        // console.log(post, "sadasda");
        var point = new Point([Number(post[0]), Number(post[1])]);
        point.setProperties({
          // 点写入额外的属性，方便展示信息的时候使用
          ...item,
        });
        var feature1 = new Feature(point);
        // feature1.setStyle(iconStyle);
        let style = new Style({
          image: new Icon({
            anchor: [0.5, 0.5],
            scale: 1.2,
            src: icon,
          }),
          text: new Text({
            font: "normal 14px 黑体",
            fill: new Fill({
              color: "rgb(255, 255, 255)",
            }),
            // // 对其方式
            textAlign: "center",
            // 基准线
            textBaseline: "middle",
            offsetY: 30,
            offsetX: 0,
            // backgroundFill: new Stroke({
            //   color: "rgba(0,0,255,0.7)",
            // }),
            // fill: new Fill({
            //   color: "rgba(236,218,20,1)",
            // }),
            // padding: [5, 5, 5, 5],
            text: `${item.scenicSpotName}`,
          }),
        });
        feature1.setStyle(style);
        poly.push(feature1);
      });
      Layer.getSource().addFeatures(poly);
      this.map.addLayer(Layer);
      this.map.on("click", function (evt) {
        const feature = that.map.forEachFeatureAtPixel(
          evt.pixel,
          function (feature) {
            return feature;
          }
        );
        const mouse = evt.coordinate;
        if (feature) {
          const featureProperties = feature.getProperties() || {};
          that.$router.push({
            path: "/oneMap",
            query: {
              id: featureProperties.geometry.values_.id,
              name: featureProperties.geometry.values_.scenicSpotName,
            },
          });
        } else {
        }
      });
    },
    setMarkersoneMap(facilityCoordinatesVOS, icon) {
      const that = this;
      console.log(
        facilityCoordinatesVOS,
        "facilityCoordinatesVOSfacilityCoordinatesVOS"
      );
      const posta = facilityCoordinatesVOS[0].facilityPoint.split(",");
      let view = this.map.getView();
      view.setCenter([Number(posta[0]), Number(posta[1])]);
      const poly = [];
      const Layer = new VectorLayer({
        // 源于"ol/layer"
        // softMove()内的时间容器
        source: new SourceLayer(), // 源于"ol/source"
      });
      facilityCoordinatesVOS.map((item) => {
        const post = item.facilityPoint.split(",");
        // console.log(post, "sadasda");
        var point = new Point([Number(post[0]), Number(post[1])]);
        point.setProperties({
          // 点写入额外的属性，方便展示信息的时候使用
          ...item,
        });
        var feature1 = new Feature(point);
        // feature1.setStyle(iconStyle);
        let style = new Style({
          image: new Icon({
            anchor: [0.5, 0.5],
            scale: 0.2,
            src: icon,
          }),
          text: new Text({
            font: "normal 14px 黑体",
            fill: new Fill({
              color: "rgb(255, 255, 255)",
            }),
            // // 对其方式
            textAlign: "center",
            // 基准线
            textBaseline: "middle",
            offsetY: 30,
            offsetX: 0,
            // backgroundFill: new Stroke({
            //   color: "rgba(0,0,255,0.7)",
            // }),
            // fill: new Fill({
            //   color: "rgba(236,218,20,1)",
            // }),
            // padding: [5, 5, 5, 5],
            text: `${item.facilityPointName}`,
          }),
        });
        feature1.setStyle(style);
        poly.push(feature1);
      });
      Layer.getSource().addFeatures(poly);
      this.map.addLayer(Layer);
      this.map.on("click", function (evt) {
        const feature = that.map.forEachFeatureAtPixel(
          evt.pixel,
          function (feature) {
            return feature;
          }
        );
        const mouse = evt.coordinate;
        if (feature) {
          const featureProperties = feature.getProperties() || {};
          that.$router.push({
            path: "/twoMap",
            query: {
              id: that.$route.query.id,
              subId: featureProperties.geometry.values_.id,
              name: featureProperties.geometry.values_.facilityPointName,
            },
          });
        } else {
        }
      });
    },
    setMarkerstwoMap(facilityCoordinatesVOS, icon) {
      const that = this;
      console.log(
        facilityCoordinatesVOS,
        "facilityCoordinatesVOSfacilityCoordinatesVOS"
      );
      const posta = facilityCoordinatesVOS[0].equipmentPoint.split(",");
      let view = this.map.getView();
      view.setCenter([Number(posta[0]), Number(posta[1])]);
      const poly = [];
      const Layer = new VectorLayer({
        // 源于"ol/layer"
        // softMove()内的时间容器
        source: new SourceLayer(), // 源于"ol/source"
      });
      facilityCoordinatesVOS.map((item) => {
        const post = item.equipmentPoint.split(",");
        // console.log(post, "sadasda");
        var point = new Point([Number(post[0]), Number(post[1])]);
        point.setProperties({
          // 点写入额外的属性，方便展示信息的时候使用
          ...item,
        });
        var feature1 = new Feature(point);

        if (item.isWarning == 1) {
          let diffTime = (this.currentDate - new Date(item.warningTime).getTime()) / 1000 / 60;
          if(diffTime > 30) {
            item.isWarning = 3;
          }
        }

        let style = new Style({
          image: new Icon({
            anchor: [0.5, 0.5],
            scale: 1.2,
            src: ICON[item.isWarning || 3],
          }),
          text: new Text({
            font: "normal 14px 黑体",
            fill: new Fill({
              color: "rgb(255, 255, 255)",
            }),
            // // 对其方式
            textAlign: "center",
            // 基准线
            textBaseline: "middle",
            offsetY: 30,
            offsetX: 0,
            // backgroundFill: new Stroke({
            //   color: "rgba(0,0,255,0.7)",
            // }),
            // fill: new Fill({
            //   color: "rgba(236,218,20,1)",
            // }),
            // padding: [5, 5, 5, 5],
            text: `${item.pointName}`,
          }),
        });
        feature1.setStyle(style);
        poly.push(feature1);
      });
      Layer.getSource().addFeatures(poly);
      this.map.addLayer(Layer);
      this.map.on("click", function (evt) {
        const feature = that.map.forEachFeatureAtPixel(
          evt.pixel,
          function (feature) {
            return feature;
          }
        );
        const mouse = evt.coordinate;
        if (feature) {
          const featureProperties = feature.getProperties() || {};
          // that.$router.push({
          //   path: "/twoMap",
          //   query: {
          //     id: that.$route.query.id,
          //     subId: featureProperties.geometry.values_.id,
          //     name: featureProperties.geometry.values_.facilityPointName,
          //   },
          // });
          if (
            that.currentId &&
            that.currentId === featureProperties.geometry.values_.id
          ) {
            // 如果当前currentId存在，且等于当前，不再重新触发
            return false;
          }
          that.currentId = featureProperties.geometry.values_.id;
          that.currentFacilityWaringState =
            featureProperties.geometry.values_.isWarning;
          that.updateInfos();
        } else {
        }
      });
    },
    initMap(center, zoom) {
      const that = this;
      const url =
        "https://ibcdsg.zj.gov.cn:8443/restapi/prod/IC33000020220406000002/services/wmts/imgmap/default/oss?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJCQ0RTR0FfZWYwNTkzNDY0NTFjMTk4MDdjNzI3Y2ZmYzNiMTNkZTgiLCJleHAiOjE2NTk0OTM5NzkyODd9.ZsxInhGuuxzv5nnqswVEuzPz2Zw0R4JUgWRWcwdd5Dg&x-bg-auth-type=jwt_auth";
      const url1 =
        "https://ibcdsg.zj.gov.cn:8443/restapi/prod/IC33000020220411000002/services/wmts/imgmap_lab/default/oss?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJCQ0RTR0FfZWYwNTkzNDY0NTFjMTk4MDdjNzI3Y2ZmYzNiMTNkZTgiLCJleHAiOjE2NTk0OTM5NzkyODd9.ZsxInhGuuxzv5nnqswVEuzPz2Zw0R4JUgWRWcwdd5Dg&x-bg-auth-type=jwt_auth";
      var projection = getProjection("EPSG:4326");
      var projectionExtent = projection.getExtent();
      var size = getWidth(projectionExtent) / 256;
      //切片名
      var resolutions = new Array(20);
      var matrixIds = new Array(20);
      for (var z = 0; z < 20; ++z) {
        // generate resolutions and matrixIds arrays for this WMTS
        resolutions[z] = size / Math.pow(2, z);
        matrixIds[z] = z;
      }
      this.map = new Map({
        target: "map",
        view: new View({
          center: center || [119.68643, 29.79388],
          zoom: zoom || 14,
          minZoom: 6,
          projection: "EPSG:4326",
        }),
        controls: olControl.defaults({
          zoom: false,
          rotate: false,
          attribution: false,
        }),
        layers: [
          new TileLayer({
            source: new WMTS({
              url: url,
              matrixSet: "EPSG:4326",
              format: "image/png",
              projection: projection,
              tileGrid: new WMTSTileGrid({
                origin: getTopLeft(projectionExtent),
                resolutions: resolutions,
                matrixIds: matrixIds,
              }),
            }),
          }),
          new TileLayer({
            source: new WMTS({
              url: url1,
              matrixSet: "EPSG:4326",
              format: "image/png",
              projection: projection,
              tileGrid: new WMTSTileGrid({
                origin: getTopLeft(projectionExtent),
                resolutions: resolutions,
                matrixIds: matrixIds,
              }),
            }),
            style: "default",
            wrapX: true,
          }),
        ],
      });

      const element = document.getElementById("popup");
      const popupContent = document.getElementById("popupContent");
      const x = that.popContent;
      // $(element).append(x);
      const popup = new Overlay({
        element: element,
        positioning: "bottom-center",
        stopEvent: true,
      });
      this.map.addOverlay(popup);
      this.map.on("pointermove", function (ev) {
        let pixel = ev.pixel;
        var hit = that.map.hasFeatureAtPixel(pixel);
        if (hit) {
          that.map.getTargetElement().style.cursor = "pointer";
        } else {
          that.map.getTargetElement().style.cursor = "";
        }
      });
    },
    // 设置地图中心区域
    // setCenterPoint(area) {
    //   this.map.setViewport(area);
    // },
    // 绘制
    setPoly(poly) {
      this.map.addOverLay(poly);
    },
    removePoly(poly) {
      this.map.removeOverLay(poly);
    },
    changeType() {
      this.type = !this.type;
      if (this.type) {
        this.map.setMapType(TMAP_HYBRID_MAP);
      } else {
        this.map.setMapType(window.TMAP_NORMAL_MAP);
      }
    },
    // 设置设施点图标
    // setMarkers(facilityCoordinatesVOS, config = null) {
    //   facilityCoordinatesVOS
    //     .filter((x) => x.facilityPoint)
    //     .map((item) => {
    //       if (!item.facilityPoint) {
    //         return false;
    //       }
    //       let lnglat = this.areaFormatter(item.facilityPoint)[0];
    //       let marker = new T.Marker(new T.LngLat(lnglat[0], lnglat[1]), config);
    //       let that = this;
    //       marker.addEventListener("click", () => {
    //         this.$router.push({
    //           path: "/twoMap",
    //           query: {
    //             id: this.$route.query.id,
    //             subId: item.id,
    //             name: item.facilityPointName,
    //           },
    //         });
    //       });
    //       this.setPoly(marker);
    //     });
    // },
    // 设置设施点文字
    // setLabelMarkers(facilityCoordinatesVOS) {
    //   facilityCoordinatesVOS
    //     .filter((x) => x.facilityPoint)
    //     .map((item) => {
    //       let lnglat = this.areaFormatter(item.facilityPoint)[0];
    //       let facilityPointName = item.facilityPointName;
    //       let label = new T.Label({
    //         text: facilityPointName,
    //         position: new T.LngLat(lnglat[0], lnglat[1]),
    //         offset: new T.Point(-70, 0),
    //       });
    //       label.setBorderColor("rgba(0, 0,0,0)");
    //       label.setBackgroundColor("rgba(0, 0,0,0)");
    //       label.setFontColor("#ffffff");
    //       this.setPoly(label);
    //     });
    // },
    // 设置景区文字
    setLabelJingqu(item) {
      item
        .filter((x) => x.coordinates)
        .map((item) => {
          let lnglat = (item.coordinates || "").split(",");
          let jingquname = item.scenicSpotName;
          let label = new T.Label({
            text: jingquname,
            position: new T.LngLat(lnglat[0], lnglat[1]),
            offset: new T.Point(-65, 15),
          });
          label.setBorderColor("rgba(0, 0,0,0)");
          label.setBackgroundColor("rgba(0, 0,0,0)");
          label.setFontColor("#ffffff");
          this.setPoly(label);
        });
    },
    areaFormatter(data) {
      let arr = (data || "").split("@");
      return arr.map((item) => {
        return item.split(",");
      });
    },
    areaFormatters(data) {
      let arr = (data || "").split("@");
      return arr.map((item) => {
        const x = item.split(",");
        x.forEach((q, index) => {
          // if (index <= 1) {
          //   return Number(q);
          // }
          q = Number(q);
        });
        return x;
      });
    },
  },
};
</script>
